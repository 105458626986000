// extracted by mini-css-extract-plugin
export var clean = "standaard-module--clean--7305f";
export var cleanInner = "standaard-module--clean-inner--f2b4c";
export var container = "standaard-module--container--fb2ff";
export var contentHeader = "standaard-module--content-header--e4a85";
export var downloadForm = "standaard-module--download-form--29d3d";
export var footer = "standaard-module--footer--327ea";
export var form = "standaard-module--form--9cfaa";
export var goToForm = "standaard-module--go-to-form--54039";
export var hr = "standaard-module--hr--47553";
export var logo = "standaard-module--logo--e415e";
export var lpLeft = "standaard-module--lp-left--040c1";
export var lpRight = "standaard-module--lp-right--b9be6";
export var mockupPosition = "standaard-module--mockup-position--8aebb";
export var mockupWrapper = "standaard-module--mockup-wrapper--edae7";
export var richtext = "standaard-module--richtext--af437";
export var roundCorners = "standaard-module--round-corners--3baad";
export var secure = "standaard-module--secure--afc02";
export var secureLogo = "standaard-module--secure-logo--6b45d";
export var secureText = "standaard-module--secure-text--ba750";
export var sponsor = "standaard-module--sponsor--eb703";
export var sponsorHeader = "standaard-module--sponsor-header--81011";
export var sponsorImg = "standaard-module--sponsor-img--7bbc5";
export var sponsorName = "standaard-module--sponsor-name--173a9";
export var sponsorWrapper = "standaard-module--sponsor-wrapper--da255";
export var sponsorWrapperBelowRichtext = "standaard-module--sponsor-wrapper-below-richtext--f41c7";