import React, { useState, useEffect } from "react"
import {
  lightBackground,
  formFieldWrapper,
  formFieldLabel,
  formField,
  formFieldError,
  formFieldWrapperOptin,
  formFieldLabelOptin,
  formCheckbox,
  formSubmit,
  thankYouMessage,
  loadingSpinner,
  formSubmitLoading
} from "./download-form.module.css"
import firebase from "gatsby-plugin-firebase"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { download } from "../../services/download"
import { getUser } from "../../services/users"
//import RichTextContent from "../rich-text-content/rich-text-content";
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"
import { get } from "lodash"
import { dataLayer } from "../../modules/analytics"
import { FaSpinner } from "react-icons/fa"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node) => {
      const link = get(node, "data.uri")
      const linkText = get(node, "content[0].value")
      /* 
        We can't use <Link to> because we don't know if this link exist
        and with <Link> URL won't get redirected by redirection.io. 
      */
      if (
        /^http(s|):\/\/(www\.|)mkbservicedesk.nl/.test(link) ||
        link.startsWith("/")
      )
        return (
          <a href={link} rel="noopener noreferrer" target="_blank">
            {linkText}
          </a>
        )
      else
        return (
          <a
            className="external-link"
            href={link}
            rel="noopener noreferrer"
            target="_blank"
          >
            {linkText}
          </a>
        )
    },
  },
}

const DownloadForm = ({
  fields,
  asset,
  darkBackground,
  contentfulId,
  title,
  buttonText,
  trackEvent,
  displayPageContentfulId,
  displayPageTitle,
  displayPosition,
  displayPagePath,
  sponsor,
  customOptin,
  extraOptins,
  submitMessageTitle,
  submitMessage,
}) => {
  darkBackground = darkBackground || false

  const [user, setUser] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  let fieldSet = []
  if (fields.includes("Voornaam")) fieldSet.push("firstname")
  if (fields.includes("Achternaam")) fieldSet.push("lastname")
  if (fields.includes("E-mailadres")) fieldSet.push("email")
  if (fields.includes("Bedrijfsnaam")) fieldSet.push("companyName")
  if (fields.includes("Telefoonnummer")) fieldSet.push("phone")
  fieldSet.push("optin")

  const fieldsInitialValues = {}
  for (let field of fieldSet) {
    fieldsInitialValues[field] = ""
  }

  /* Set initial values based on URL parameters */
  if (typeof window !== "undefined") {
    let params = new URLSearchParams(window.location.search)
    for (let field of Object.keys(fieldsInitialValues)) {
      if (params.has(field)) {
        fieldsInitialValues[field] = params.get(field)
      }
    }
  }

  const [initialValues, setInitialValues] = useState(fieldsInitialValues)

  useEffect(() => {
    if (!firebase) return
    firebase.auth().onAuthStateChanged(async (authUser) => {
      if (!authUser) return await firebase.auth().signInAnonymously()
      const userData = await getUser(authUser.uid)
      if (userData) {
        // loop over the initialValues to see if there is matching userData
        for (let key of Object.keys(initialValues)) {
          if (userData[key] && initialValues[key] === "")
            initialValues[key] = userData[key]
        }
        setInitialValues(initialValues)
      }
      setUser(authUser)
    })
  }, [initialValues, user])

  return (
    <div className={darkBackground ? "" : lightBackground}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          try {
            await download(
              user.uid,
              values,
              asset,
              contentfulId,
              title,
              displayPageContentfulId,
              displayPageTitle,
              displayPosition,
              displayPagePath,
              fields
            )
            setIsLoading(false);
            setSubmitted(true)
            if (trackEvent) dataLayer(trackEvent)
          } catch (err) {
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            {fields.includes("Voornaam") && (
              <div className={formFieldWrapper} key="firstname">
                <label
                  className={`${formFieldLabel} download-form-label`}
                  htmlFor="firstname"
                >
                  Voornaam
                </label>
                <Field
                  validate={(value) =>
                    value ? undefined : "Voornaam is verplicht"
                  }
                  className={formField}
                  type="text"
                  name="firstname"
                  disabled={submitted}
                />
                <ErrorMessage
                  className={`${formFieldError} download-form-field-error`}
                  name="firstname"
                  component="div"
                />
              </div>
            )}

            {fields.includes("Achternaam") && (
              <div className={formFieldWrapper} key="lastname">
                <label
                  className={`${formFieldLabel} download-form-label`}
                  htmlFor="lastname"
                >
                  Achternaam
                </label>
                <Field
                  validate={(value) =>
                    value ? undefined : "Achternaam is verplicht"
                  }
                  className={formField}
                  type="text"
                  name="lastname"
                  disabled={submitted}
                />
                <ErrorMessage
                  className={`${formFieldError} download-form-field-error`}
                  name="lastname"
                  component="div"
                />
              </div>
            )}

            {fields.includes("E-mailadres") && (
              <div className={formFieldWrapper} key="email">
                <label
                  className={`${formFieldLabel} download-form-label`}
                  htmlFor="email"
                >
                  E-mailadres
                </label>
                <Field
                  validate={(value) =>
                    value ? undefined : "E-mailadres is verplicht"
                  }
                  className={formField}
                  type="email"
                  name="email"
                  disabled={submitted}
                />
                <ErrorMessage
                  className={`${formFieldError} download-form-field-error`}
                  name="email"
                  component="div"
                />
              </div>
            )}

            {fields.includes("Bedrijfsnaam") && (
              <div className={formFieldWrapper} key="companyName">
                <label
                  className={`${formFieldLabel} download-form-label`}
                  htmlFor="companyName"
                >
                  Bedrijfsnaam
                </label>
                <Field
                  validate={(value) =>
                    value ? undefined : "Bedrijfsnaam is verplicht"
                  }
                  className={formField}
                  type="companyName"
                  name="companyName"
                  disabled={submitted}
                />
                <ErrorMessage
                  className={`${formFieldError} download-form-field-error`}
                  name="companyName"
                  component="div"
                />
              </div>
            )}

            {fields.includes("Telefoonnummer") && (
              <div className={formFieldWrapper} key="phone">
                <label
                  className={`${formFieldLabel} download-form-label`}
                  htmlFor="phone"
                >
                  Telefoonnummer
                </label>
                <Field
                  validate={(value) =>
                    value ? undefined : "Telefoonnummer is verplicht"
                  }
                  className={formField}
                  type="phone"
                  name="phone"
                  disabled={submitted}
                />
                <ErrorMessage
                  className={`${formFieldError} download-form-field-error`}
                  name="phone"
                  component="div"
                />
              </div>
            )}

            {fields.includes("Bedrijfsgrootte") && (
              <div className={formFieldWrapper} key="companyEmployees">
                <label
                  className={`${formFieldLabel} download-form-label`}
                  htmlFor="companyEmployees"
                >
                  Bedrijfsgrootte
                </label>
                <Field
                  as="select"
                  validate={(value) =>
                    value ? undefined : "Bedrijfsgrootte is verplicht"
                  }
                  className={formField}
                  type="companyEmployees"
                  name="companyEmployees"
                  disabled={submitted}
                >
                  <option aria-label="leeg" value=""></option>
                  <option value="1">ZZP’er / Eenmanszaak</option>
                  <option value="2">2 personen</option>
                  <option value="3-4">3 of 4 personen</option>
                  <option value="5-9">5 t/m 9 personen</option>
                  <option value="10-19">10 t/m 19 personen</option>
                  <option value="20-49">20 t/m 49 personen</option>
                  <option value="50-99">50 t/m 99 personen</option>
                  <option value="100-249">100 t/m 249 personen</option>
                  <option value="250+">250 of meer personen</option>
                </Field>
                <ErrorMessage
                  className={`${formFieldError} download-form-field-error`}
                  name="companyEmployees"
                  component="div"
                />
              </div>
            )}

            {fields.includes("Branche") && (
              <div className={formFieldWrapper} key="companyIndustry">
                <label
                  className={`${formFieldLabel} download-form-label`}
                  htmlFor="companyIndustry"
                >
                  Branche
                </label>
                <Field
                  as="select"
                  validate={(value) =>
                    value ? undefined : "Branche is verplicht"
                  }
                  className={formField}
                  type="companyIndustry"
                  name="companyIndustry"
                  disabled={submitted}
                >
                  <option aria-label="leeg" value=""></option>
                  <option value="A">Landbouw, bosbouw en visserij</option>
                  <option value="B-E">Nijverheid (geen bouw) en energie</option>
                  <option value="F">Bouwnijverheid</option>
                  <option value="G-I">Handel, vervoer en horeca</option>
                  <option value="J">Informatie en communicatie</option>
                  <option value="K">Financiële dienstverlening</option>
                  <option value="L">
                    Verhuur en handel van onroerend goed
                  </option>
                  <option value="M-N">Zakelijke dienstverlening</option>
                  <option value="O-Q">Overheid en zorg</option>
                  <option value="R-U">
                    Cultuur, recreatie, overige diensten
                  </option>
                </Field>
                <ErrorMessage
                  className={`${formFieldError} download-form-field-error`}
                  name="companyIndustry"
                  component="div"
                />
              </div>
            )}

            <div style={{ display: "flex", flexDirection:"column", gap: "5px"}}>
              <div className={formFieldWrapperOptin} key="optin">
                {!customOptin && (
                  <label
                    className={`${formFieldLabelOptin} download-form-optin`}
                    htmlFor="optin"
                  >
                    Bij het {asset?.file?.url && <>downloaden</>}{" "}
                    {!asset?.file?.url && <>verzenden van mijn gegevens</>} ga
                    ik akkoord met de{" "}
                    <a href="/algemene-voorwaarden/" target="_blank">
                      Algemene voorwaarden
                    </a>{" "}
                    en de{" "}
                    <a href="/privacy-verklaring/" target="_blank">
                      Privacy Statement
                    </a>{" "}
                    van MKB Servicedesk B.V. en geef ik toestemming om door MKB
                    Servicedesk{" "}
                    {sponsor?.name && (
                      <>
                        en/of{" "}
                        {sponsor.name === "OHRA"
                          ? "OHRA met aanbiedingen voor haar verzekeringen"
                          : sponsor.name}{" "}
                      </>
                    )}
                    benaderd te worden.
                  </label>
                )}
                {customOptin && (
                  <label
                    className={`${formFieldLabelOptin} download-form-optin`}
                    htmlFor="optin"
                  >
                    {renderRichText(customOptin, renderOptions)}
                  </label>
                )}
                <Field
                  validate={(value) =>
                    value ? undefined : "Opt-in is nodig om verder te gaan"
                  }
                  className={formCheckbox}
                  type="checkbox"
                  name="optin"
                  disabled={submitted}
                />
                <ErrorMessage
                  className={`${formFieldError} download-form-field-error`}
                  name="optin"
                  component="div"
                />
              </div>

              {extraOptins.extraOptin1 && (
                <div className={formFieldWrapperOptin} key="extraOptin1">
                  <label
                    className={`${formFieldLabelOptin} download-form-optin`}
                    htmlFor="extraOptin1"
                  >
                    {renderRichText(extraOptins.extraOptin1, renderOptions)}
                  </label>

                  <Field
                    validate={(value) =>
                      extraOptins.extraOptin1Required && !value
                        ? "Opt-in is nodig om verder te gaan"
                        : undefined
                    }
                    className={formCheckbox}
                    type="checkbox"
                    name="extraOptin1"
                    disabled={submitted}
                  />
                  <ErrorMessage
                    className={`${formFieldError} download-form-field-error`}
                    name="extraOptin1"
                    component="div"
                  />
                </div>
              )}

              {extraOptins.extraOptin2 && (
                <div className={formFieldWrapperOptin} key="extraOptin2">
                  <label
                    className={`${formFieldLabelOptin} download-form-optin`}
                    htmlFor="extraOptin2"
                  >
                    {renderRichText(extraOptins.extraOptin2, renderOptions)}
                  </label>

                  <Field
                    validate={(value) =>
                      extraOptins.extraOptin2Required && !value
                        ? "Opt-in is nodig om verder te gaan"
                        : undefined
                    }
                    className={formCheckbox}
                    type="checkbox"
                    name="extraOptin2"
                    disabled={submitted}
                  />
                  <ErrorMessage
                    className={`${formFieldError} download-form-field-error`}
                    name="extraOptin2"
                    component="div"
                  />
                </div>
              )}
            </div>

            {!submitted && (
              <button
                className={`${formSubmit} ${isLoading ? formSubmitLoading : ''} download-form-submit`}
                type="submit"
                disabled={isSubmitting || isLoading}
              >
               {isLoading && <FaSpinner className={loadingSpinner} style={{marginRight: '6px', marginBottom: '-2px'}} />} {buttonText || `Download`}
              </button>
            )}
            {submitted && (
              <div className={thankYouMessage}>
                <h4>{submitMessageTitle ?? "Bedankt!"}</h4>
                <p>
                  {submitMessage ??
                    "We hebben je gegevens ontvangen, indien nodig nemen we spoedig contact met je op."}
                </p>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default DownloadForm
