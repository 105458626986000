import firebase from "gatsby-plugin-firebase"
import { getIpAddressInfo } from "../modules/ipAddressInfo";
import { gaClientId } from "../services/tracking"


const createBlobAndDownload = async (url) => {
    // Fetch file data using fetch or useAxios
    const response = await fetch(url)

    // Check for successful response
    if (!response.ok) {
      throw new Error("Failed to download file")
    }

    // Create a blob and download it
    const blob = await response.blob()
    const downloadLink = document.createElement("a")
    downloadLink.href = window.URL.createObjectURL(blob)
    downloadLink.download = url.split("/").pop() // Extract filename
    downloadLink.click()
    window.URL.revokeObjectURL(downloadLink.href)
  }

export const download = async (
    userId,
    formValues,
    asset,
    contentfulId,
    title,
    displayPageContentfulId,
    displayPageTitle,
    displayPosition,
    displayPagePath,
    fields
) => {
    if (asset && asset.file && asset.file.url) await createBlobAndDownload(`https:${asset.file.url}`);
    try {
        // Only set optinIp/date if not set
        const userRef = firebase.firestore().collection("users").doc(userId);
        const doc = await userRef.get();
        if (!doc.exists ||
            (!doc.get('optinIp') && formValues.optin)
            ||
            (!doc.get('signupIp'))
        ) {
            const ipAddressInfo = await getIpAddressInfo();

            if (formValues.optin && !doc.get('optinIp')) {
                formValues.optinIp = ipAddressInfo.ip;
                formValues.optinDate = firebase.firestore.FieldValue.serverTimestamp();
            }

            if (!doc.get('signupIp')) {
                formValues.signupIp = ipAddressInfo.ip;
                formValues.signupDate = firebase.firestore.FieldValue.serverTimestamp();
            }
        }

        await userRef.set(formValues, {
            merge: true
        });

        
        await userRef
            .collection("downloads")
            .add({
                "gaClientId": gaClientId(),
                "displayPageContentfulId": displayPageContentfulId,
                "displayPageTitle": displayPageTitle,
                "displayPosition": displayPosition,
                "displayPagePath": displayPagePath,
                "contentfulId": contentfulId,
                "title": title,
                "downloadedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "requestedFormFieldNames": fields,
                "requestedFormFields": fields.length,
                "formValues": formValues,
                "contentfulAsset": asset
            });

    } catch (err) {
        console.log(err);
    }

}

export const validate = (values, fields) => {
    const errors = {};
    for (let field of fields) {
        if (field.required === true && !values[field.internalName]) {
            errors[field.internalName] = 'Dit veld is verplicht';
        } else if (field.validateRegex
            && !(new RegExp(field.validateRegex).test(values.email))) {
            errors[field.internalName] = field.errorText;
        }
    }
    return errors;
}

