import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const MediaImage = ({ image, layout = "fixed", alt, ...props }) => {
  const altText = alt ?? image.title
  if (image.gatsbyImageData) {
    return (
      <GatsbyImage
        layout={layout}
        image={image.gatsbyImageData}
        alt={altText}
        {...props}
      />
    )
  } else if (image.file) {
    return <img src={image.file.url} alt={altText} {...props} />
  } else {
    return null // Display nothing if neither gatsbyImageData nor url is available
  }
}

export default MediaImage
